import React from 'react'
import { IcoCog, IcoComentarios, IcoDados, IcoEmpresa, IcoEnvelope, IcoGrupos, IcoList, IcoLogOut, IcoUsers } from "../components"

export const menuItensHeader = (e, nls) => {
  return [
    { 
      id: "visualizarProjeto", 
      label: nls.menus.visualizar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ConfigurarProjeto", 
      label: nls.menus.configurar, 
      go: "/meusprojetos/novo/configurar", 
      icon: '' 
    },
    // ...e?.creditoProjetos?[{ 
    //   id: "CriarProjeto", 
    //   label: nls.menus.criar, 
    //   go: "/meusprojetos", 
    //   icon: '' 
    // }]:[],
    { 
      id: "CriarProjeto", 
      label: nls.menus.criar, 
      go: "/meusprojetos", 
      icon: '' 
    },
    { 
      id: "ComprarProjeto", 
      label: nls.menus.comprar, 
      go: "/projetos", 
      icon: '' 
    },
  ]
}
  
  
export const menuItens = () => {
  return [
    { 
      id: "Resumodoprojeto", 
      label: "Resumo do projeto", 
      go: "/resumoprojeto", 
      icon: <IcoDados /> 
    },
    { 
      id: "empresas", 
      label: "Empresas", 
      icon: <IcoEmpresa />,
      sub: [
        { 
          id: "CSAT", 
          label: "CSAT", 
          go: "/csat",
        },
        { 
          id: "NPS", 
          label: "NPS", 
          go: "/nps",
        },
        { 
          id: "CSATConcorrentes", 
          label: "CSAT: comparação com concorrentes", 
          go: "/csatconcorrentes",
        },
        { 
          id: "NPSConcorrentes", 
          label: "NPS: comparação com concorrentes", 
          go: "/npstconcorrentes",
        }
      ]
    },
    { 
      id: "lojas", 
      label: "Lojas", 
      icon: <IcoGrupos />,
      sub: [
        { 
          id: "Resumodosgrupos", 
          label: "Resumo dos grupos", 
          go: "/resumogrupos",
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos estabelecimentos", 
          go: "/csatestabelecimentos",
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos estabelecimentos", 
          go: "/npsestabelecimentos",
        },
        { 
          id: "RankingCSAT", 
          label: "CSAT dos estabelecimentos: comparação com concorrentes", 
          go: "/csatestabelecimentosconcorrentes",
        },
        { 
          id: "RankingNPS", 
          label: "NPS dos estabelecimentos: comparação com concorrentes", 
          go: "/npsestabelecimentosconcorrentes",
        },
        { 
          id: "Indicadores", 
          label: "Indicadores", 
          go: "/indicadores",
        },
        { 
          id: "“AnáliseSWOT", 
          label: "Análise SWOT", 
          go: "/analiseswot",
        }
      ]
    },
    { 
      id: "Comentarios", 
      label: "Comentários", 
      go: "/comentarios", 
      icon: <IcoComentarios /> 
    }
  ]
}

export const menuUserInfo = (e, nls) => {
  return [
    { 
      id: "Dadoscadastro", 
      label: nls.menus.dadosCadastro, 
      action: "modalMeusDadosCadastro", 
      icon: <IcoCog /> 
    },
    ...e?.VerGerenciarUsuarios?[{ 
      id: "Gerenciarusuários", 
      label: nls.menus.gerenciarUsuarios,  
      go: "/usuarios", 
      icon: <IcoUsers /> 
    }]:[],
    ...e?.VerHistoricoFaturas?[{ 
      id: "Históricodefaturas", 
      label: nls.menus.historicoFaturas,
      icon: <IcoList />
    }]:[],
    { 
      id: "MinhasAssinaturas", 
      label: nls.menus.minhasAssinaturas, 
      go: "/minhasassinaruras", 
      icon: <IcoEnvelope /> 
    },
    { 
      id: "Suporte", 
      label: nls.menus.suporte, 
      action: "contatoSuporteModal", 
      icon: <IcoEnvelope /> 
    },
    { 
      id: "Sair", 
      label: nls.menus.sair, 
      icon: <IcoLogOut /> 
    },
  ]
}