import React, { useEffect, useState } from 'react'
import Header from './header'
import { Alert, Loading } from '../components'
import SidebarLeft from './sidebarLeft'
import { useDispatch, useSelector } from 'react-redux'
import { listarIdiomas, listarProjetos, loged, modalOpen, setInfoGrupo, setInfoProjeto, verificaCreditoConta } from './redux/layoutActions'
import ModalInforme from './modalInforme'
import CentralAjuda from '../modules/centralAjuda/centralAjuda'
import ModalAlerta from './modalAlerta'
import { history } from '../helpers'
// import { menuItens } from './menuItens'

export default ({ children, sidebar }) => { 
  const dispatch = useDispatch()
  const { 
    load, 
    logIn, 
    projeto, 
    projetos,
    grupo,
    grupos,
    sistema,
    usuario,
  } = useSelector(state=> state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const [heigthHeader, setHeigthHeader] = useState(0)
  useEffect(() => {
    setHeigthHeader(document.getElementById('box-header')?.offsetHeight)
  }, [logIn])
  const heightApp = { height: `calc(100vh - ${heigthHeader}px)` }
  
  useEffect(()=> {
    setTimeout(() => {
      dispatch(loged())
    }, 500);
  },[dispatch])

  useEffect(()=> {   
    if (logIn && sistema?.login?.recebePopupBoasVindas) { 
      dispatch(modalOpen('modalInforme'))
    }
  },[logIn])

  useEffect(()=> {
    if (logIn) {
      dispatch([
        listarIdiomas(sistema),
        verificaCreditoConta()
      ])
    }
  },[logIn])

  useEffect(()=> {
    if (usuario.email) {
      dispatch(listarProjetos(usuario.email))
      
    }
  },[usuario?.email])
  
  useEffect(()=> {
    const hasProject = projeto.id
    const hasGroup = !grupo
    if (!hasProject && projetos.length) {
      dispatch(setInfoProjeto(projetos[0]))
    }
    if (!hasGroup && grupos.length) {
      dispatch(setInfoGrupo(grupos[0]))
    }
  },[projetos, grupos])

  if (!logIn) {
    return <Loading title={nls.carregando} />
  }
  return (
    <>
      {load? <Loading title={nls.carregando} />:null}
      <Alert />
      <div id='box-app'>
        <Header />
        <div id='box-app-container' style={sidebar?{display: 'flex'}:{}}>
        {sidebar?<SidebarLeft />:null}
          <section id='box-container' style={heightApp}>
            <div id='box-container-page' style={sidebar?{padding: '10px 30px 30px 30px'}:{}}>
              {
                sistema?.login?.mensagemBannerPromocional? <div className='box-alerta danger' onClick={()=> history.push(`upgradeconta`)}>
                  {sistema?.login?.mensagemBannerPromocional?
                  sistema?.login?.mensagemBannerPromocional:
                  <p>Notamos que um pagamneto <strong>não foi detectado</strong> e os serviços atuais podem ser cancelados.</p>
                  }
                </div>
                : null
              }
              {children}
            </div>
          </section>
        </div>
      </div>
      <ModalInforme />
      <CentralAjuda />
      <ModalAlerta/>
    </>
  )
}
